@import '@simpl/fonts/dist/styles/siemens-serif';
@import '@simpl/fonts/dist/styles/siemens-sans';

@import '@simpl/element-icons/dist/style/simpl-element-icons.scss'; // sass-lint:disable-line clean-import-paths

// Use Element Bootstrap Theme
@import '@simpl/element-theme/src/theme';

// Import Element components
@import '@simpl/element-ng/simpl-element-ng';


main {
  padding-top: 68px;
}

@include media-breakpoint-up(md) {
  main {
    padding-top: 128px;
  }
}

@include media-breakpoint-up(lg) {
  main {
    padding-top: 108px;
  }
}

.search-bar-icon{
  position: absolute;
    inset-block-start: 4px;
    inset-inline-start: 4px;
    color: var(--search-bar-icon-color);
    line-height: 1;
}

.rpanel-size--wide{
  --rpanel-size: 1000px !important;
}

.right-items{
  text-align: right;
}

.tag {
  align-items: center;
  background-color: #087991;
  border: none;
  border-radius: 6px;
  box-shadow: none;
  color: #fff;
  font-size: 13px;
  line-height: 12px;
  max-width: 100%;
  padding: 10px;
  position: relative;
  margin-right: 3px;
}

.center-div {
  text-align: center;
}

.projectSubscriptionsTable{
  width: 90%;
}
.small-spinner{
  svg{
      inline-size: var(--loading-spinner-size, 30px) !important;
      block-size: var(--loading-spinner-size, 30px) !important;
  }
}

.ml-50 {
  margin-left: 50px;
}